/** @jsx jsx */
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
import Link from '../Link';
import PropTypes from 'prop-types';
import { Box, jsx, Text, useThemeUI } from 'theme-ui';
import Icon from '../Icon';

const TextWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.variant && 'space-between'};
`;

const LinkWrapper = ({ to, children, download, ...props }) => {
  if (to) {
    return (
      <Box {...props}>
        <Link to={to} download={download}>
          {children}
        </Link>
      </Box>
    );
  }
  return <Box {...props}>{children}</Box>;
};

/* TODO: responsive styling, states, different sizes etc */
const Button = ({
  variant,
  iconBefore: IconBefore,
  iconAfter: IconAfter,
  children,
  as,
  styles,
  className,
  ...props
}) => {
  const backgroundColor = variant === 'solid' ? 'primary' : 'transparent';

  const { theme } = useThemeUI();

  return (
    <LinkWrapper as={as} variant={`button.${variant}`} {...props}>
      <TextWrapper variant={variant}>
        <Text variant="navigationTitleAlt" as="span" sx={{ mt: 1, letterSpacing: '2px', whiteSpace: 'wrap' }}>
          {IconBefore && (
            <Icon size={2} sx={{ ml: 0, mr: 1, display: 'inline-block' }} fill={['primary']}>
              <IconBefore />
            </Icon>
          )}
          <span>{children}</span>
          {IconAfter && (
            <Icon size={2} sx={{ ml: 1, mr: 0, display: 'inline-block' }} fill={['primary']}>
              <IconAfter />
            </Icon>
          )}
        </Text>
      </TextWrapper>
    </LinkWrapper>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['solid', 'outline', 'text']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  iconBefore: PropTypes.object,
  iconAfter: PropTypes.object,
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  to: PropTypes.string.isRequired,
};

Button.defaultProps = {
  variant: 'solid',
  size: 'medium',
  iconBefore: null,
  iconAfter: null,
  as: 'button',
};
export default Button;
