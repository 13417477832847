import { Box, Grid } from '@theme-ui/components';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Icon';
import ModuleWrapper from '../ModuleWrapper';
import wave from './assets/wave.svg';
import { StaticQuery, graphql } from 'gatsby';
import Heading from '../../Heading';
import Button from '../../Button';
import RatingStars from '../../RatingStars/RatingStars';

const ModuleProvenExpert = ({ subTitle, cta, spaceAbove, spaceBelow, variant, ...props }) => {
  const { scrollYProgress } = useViewportScroll();
  const translateY = useTransform(scrollYProgress, [0, 1], [400, 350]);
  const translateYRing = useTransform(scrollYProgress, [0, 1], [100, -10]);
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 120]);

  return (
    <StaticQuery
      query={graphql`
        query {
          sanitySiteSettings(provenExpert: {}) {
            provenExpert {
              value
              numerator
              comparator
            }
          }
        }
      `}
      render={(data) => (
        <ModuleWrapper
          spaceAbove={spaceAbove}
          spaceBelow={spaceBelow}
          variant={variant}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            '::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              zIndex: 0,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${wave})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            },
          }}
        >
          <Grid
            gap={2}
            columns={[2, null, 12]}
            variant="default"
            {...props}
            sx={{ zIndex: 1, position: 'relative', ...props.sx }}
          >
            <Box
              itemscope
              itemtype="https://schema.org/Organization"
              sx={{
                gridColumn: ['1/-1', null, '2/12'],
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Heading as={'p'} variant="h2" sx={{ textAlign: 'center', color: 'inherit', mb: 6 }} intro={subTitle}>
                  <span itemprop="name">GGW Vorsorgeberatung</span>
                </Heading>
                <RatingStars
                  rating={data?.sanitySiteSettings?.provenExpert.value}
                  numerator={data?.sanitySiteSettings?.provenExpert.numerator}
                  comparator={data?.sanitySiteSettings?.provenExpert.comparator}
                />
                <Button
                  variant={cta.buttonType}
                  to={cta.externalURL || cta.reference?.slug?.current}
                  sx={{ mx: 'auto', mt: 6, justifyContent: 'center', width: 'max-content' }}
                >
                  {cta.buttonCaption}
                </Button>
              </Box>
            </Box>
          </Grid>
        </ModuleWrapper>
      )}
    />
  );
};

ModuleProvenExpert.propTypes = {
  head: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  ...ModuleWrapper.propTypes,
};

ModuleProvenExpert.defaultProps = {
  head: null,
};

export default ModuleProvenExpert;
