import { AspectRatio, Box, Grid } from '@theme-ui/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ModuleWrapper from '../ModuleWrapper';
const ModuleTextWithImage = ({
  children,
  imagePosition,
  showDecorativeElements,
  img,
  alt,
  title,
  styles,
  spaceAbove,
  spaceBelow,
  variant,
  ...props
}) => {
  const aspectRatio = img.asset?.metadata?.dimensions?.aspectRatio || 1;
  const image = img.asset?.gatsbyImageData

  return (
    <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant}>
      <Grid
        gap={2}
        columns={[2, null, 12]}
        sx={{
          maxWidth: [null, null, null, 134, 150],
          mx: ['10px', null, null, 'auto'],
        }}
      >
        <Box
          sx={{
            gridColumn: ['1/-1', null, imagePosition === 'left' ? '1/7' : '7/-1'],
            order: [2, null, imagePosition === 'left' ? 1 : 2],
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            gridColumn: ['1/-1', null, imagePosition === 'left' ? '8/-1' : '1/6'],
            order: [1, null, imagePosition === 'left' ? 2 : 1],
          }}
        >
          <Box sx={{ position: 'relative', mb: [0, null, showDecorativeElements ? 5 : 0] }}>

            {image ? <AspectRatio ratio={aspectRatio}>
              <GatsbyImage
                image={image}
                width={410}
                style={{
                  aspectRatio,
                  width: '100%'
                }}
                alt={alt}
                title={title} />
            </AspectRatio> : null}


          </Box>
        </Box>
      </Grid>
    </ModuleWrapper>
  );
};

ModuleTextWithImage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  imagePosition: PropTypes.oneOf(['left', 'right']),
  showDecorativeElements: PropTypes.bool,
  img: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  ...ModuleWrapper.propTypes,
};

ModuleTextWithImage.defaultProps = {
  imagePosition: 'left',
  showDecorativeElements: false,
  alt: '',
  title: '',
};

export default ModuleTextWithImage;
