import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Box } from '@theme-ui/components';
import List from '../../List';
import ModuleWrapper from '../ModuleWrapper';
const ModuleList = ({ children, items, type, spaceAbove, spaceBelow, variant, ...props }) => {
  return (
    <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant}>
      <Grid
        gap={2}
        columns={[2, null, 12]}
        sx={{
          maxWidth: [null, null, null, 134, 150],
          mx: ['10px', null, null, 'auto'],
        }}
        {...props}
      >
        <Box
          sx={{
            gridColumn: ['1/-1', null, '1/7'],
            order: [1, null, 1],
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            gridColumn: ['1/-1', null, '8/-1'],
            order: [2, null, 2],
          }}
        >
          <List
            items={items.map(item => (
              <>{item}</>
            ))}
            type={type}
          />
        </Box>
      </Grid>
    </ModuleWrapper>
  );
};

ModuleList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  ...ModuleWrapper.propTypes,
};

export default ModuleList;
