import { Box, Flex, Grid } from '@theme-ui/components';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useThemeUI } from 'theme-ui';
import Button from '../../Button';
import Heading from '../../Heading';
import { ReactComponent as CaretRight } from '../../Icon/assets/caret-right.svg';
import Link from '../../Link';
import ModuleWrapper from '../ModuleWrapper';

const ModuleRelatedContent = ({ component, spaceAbove, spaceBelow, variant }) => {
  const [refLeft, inViewLeft] = useInView({ triggerOnce: false });
  const [refRight, inViewRight] = useInView({ triggerOnce: false });
  const overflowIndicatorVariants = {
    hidden: { opacity: 0, width: 0 },
    visible: { opacity: 1, width: 'min(10vw, 100px)' },
  };
  const { theme } = useThemeUI();

  const commonOverflowIndicatorStyles = {
    display: 'block',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: 'min(10vw, 100px)',
    zIndex: 1,
    pointerEvents: 'none',
  };

  const themeBackgroundColor = theme?.colors?.backgrounds[variant];

  return (
    <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant} sx={{ position: 'relative' }}>
      <Grid gap={2} columns={1} variant="default" sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex' }}>
          {component.relatedContent.length > 1 ? (
            <>
              <motion.div
                animate={inViewLeft ? 'hidden' : 'visible'}
                variants={overflowIndicatorVariants}
                style={{
                  ...commonOverflowIndicatorStyles,
                  background: `linear-gradient(90deg, ${themeBackgroundColor}, rgba(255,255,255,0))`,
                  left: '-1px',
                }}
              ></motion.div>
              <motion.div
                animate={inViewRight ? 'hidden' : 'visible'}
                variants={overflowIndicatorVariants}
                style={{
                  ...commonOverflowIndicatorStyles,
                  background: `linear-gradient(-90deg, ${themeBackgroundColor}, rgba(255,255,255,0))`,
                  right: '-1px',
                }}
              ></motion.div>
            </>
          ) : null}
          <Flex
            sx={{
              width: '100%',
              display: 'flex',
              overflowY: 'hidden',
              scrollSnapType: 'x mandatory ',
              position: 'relative',
            }}
          >
            {component.relatedContent.length > 1 ? (
              <Box
                ref={refLeft}
                sx={{
                  width: '1px',
                  border: '1px solid transparent',
                  height: '100px',
                }}
              ></Box>
            ) : null}
            {component.relatedContent.map((content) => {
                const aspectRatio = content.hero.image.asset?.metadata?.dimensions?.aspectRatio || 1;
                const image = content.hero.image.asset?.gatsbyImageData 
              
              return (
                <Box
                  key={content.id}
                  sx={{
                    minWidth: 'min(362px, 80vw)',
                    scrollSnapAlign: 'center',
                    margin: '2',
                    '&:only-child': {
                      maxWidth: [null, null, '50%'],
                    },
                  }}
                >
                  <Link to={content.slug.current}>
                    {image ? <GatsbyImage
                      image={image}
                      title={content.name}
                      alt={content.name}
                      style={{ width: '100%' }} /> : null}
                    
                  </Link>

                  <Heading as={'h3'} variant="h3" sx={{ mt: 5, mb: 3 }}>
                    <Link to={content.slug.current} sx={{ color: 'inherit' }}>
                      {content.hero.title}
                    </Link>
                  </Heading>

                  <Button variant="text" iconAfter={CaretRight} to={content.slug.current}>
                    Mehr erfahren
                  </Button>
                </Box>
              );
            })}
            {component.relatedContent.length > 1 ? (
              <Box ref={refRight} sx={{ width: '1px', border: '1px solid transparent', height: '100px' }}></Box>
            ) : null}
          </Flex>
        </Box>
      </Grid>
    </ModuleWrapper>
  );
};

ModuleRelatedContent.propTypes = {
  ...ModuleWrapper.propTypes,
  component: PropTypes.shape({
    relatedContent: PropTypes.array.isRequired,
  }),
};

ModuleRelatedContent.defaultProps = {};

export default ModuleRelatedContent;
