import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@theme-ui/components';

const ModuleWrapper = ({ variant, spaceAbove, spaceBelow, children, ...props }) => (
  <Container pt={spaceAbove} pb={spaceBelow} variant={variant} as="section" {...props}>
    {children}
  </Container>
);
ModuleWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  spaceAbove: PropTypes.string,
  spaceBelow: PropTypes.string,
  variant: PropTypes.string,
};

ModuleWrapper.defaultProps = {
  spaceAbove: 'none',
  spaceBelow: 'none',
  variant: 'backgrounds.light',
};

export default ModuleWrapper;
