import React from 'react';
import ModuleWrapper from '../ModuleWrapper';

import { Grid, Box, Text } from 'theme-ui';
// import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage, getGatsbyImageData } from 'gatsby-plugin-image';

import Heading from '../../Heading';
import { graphql, useStaticQuery } from 'gatsby';
import Richtext from '../../Richtext';
import styled from '@emotion/styled';
import Link from '../../Link';

const StyledDt = styled.dt`
  margin-bottom: ${(props) => props.theme.sizes[3]}px;
`;
const StyledDd = styled.dd`
  margin-bottom: ${(props) => props.theme.sizes[2]}px;
`;

const StyledFlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > div > p {
    margin-bottom: ${(props) => props.theme.sizes[0]}px;
  }
`;

const ModuleCredit = ({ title, subTitle, spaceAbove, spaceBelow, variant }) => {
  const { allSanityPage } = useStaticQuery(
    graphql`
      query {
        allSanityPage {
          edges {
            node {
              pageContent {
                ... on SanityModuleImageRow {
                  images {
                    _rawCredit
                    asset{
                      gatsbyImageData(aspectRatio: 1, width: 64)
                      mimeType
                      url
                    }
                  }
                }
                ... on SanityModuleImageWithText {
                  image {
                    _rawCredit
                    asset{
                      gatsbyImageData(aspectRatio: 1, width: 64)
                      mimeType
                      url
                    }
                  }
                }
              }
              hero {
                image {
                  _rawCredit
                  asset{
                    gatsbyImageData(aspectRatio: 1, width: 64)
                    mimeType
                    url
                  }
                }
              }
              slug {
                current
              } 
              title
            }
          }
        }
      }
    `
  );
  const creditElements = allSanityPage.edges.map((page) => {
    const { node } = page;
    const heroCredit = {
      credit: node.hero?.image?._rawCredit,
      src: node.hero?.image,
      mimeType: node.hero.image.asset.mimeType
    };
    

    const inContentCredit =
      node.pageContent &&
      node.pageContent
        .map((contentItem) => {
          if (contentItem.image?._rawCredit) {
            return {
              credit: contentItem.image?._rawCredit,
              src: contentItem.image,
              mimeType: contentItem.image?.asset.mimeType
            };
          }
        })
        .filter((x) => x);
    return { page: node.title, slug: node.slug.current, heroCredit, inContentCredit };
  });

  return (
    <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant}>
      <Grid gap={2} columns={[2, null, 12]} variant="default">
        <Box
          sx={{
            gridColumn: ['1/-1', null, '2/12'],
            mb: 9,
          }}
        >
          <Heading
            sx={{ textAlign: ['left', null, 'center'], color: 'inherit' }}
            as={'h2'}
            variant="h2"
            intro={subTitle}
          >
            {title}
          </Heading>
        </Box>
        <Box
          sx={{
            gridColumn: ['1/-1', null, '3/11'],
          }}
        >
          <dl>
            {creditElements
              .sort((item) => item.slug)
              .reverse()
              .map((element) => {
                if (element.heroCredit.credit || element.inContentCredit.length > 0) {
                  return (
                    <Box sx={{ mb: 8 }} as="dl">
                      <StyledDt>
                        <Text variant={'h4'} as={'p'}>
                          {element.page}
                        </Text>
                        <Text variant={'text.default'} as="p" sx={{ mt: 2, mb: 4, color: 'primary' }}>
                          <Link
                            as="a"
                            sx={{
                              color: 'primary',
                              '&:hover': {
                                color: 'primaryActive',
                              },
                            }}
                            to={`${element.slug}`}
                          >
                            {element.slug}
                          </Link>
                        </Text>
                      </StyledDt>
                      {element.heroCredit.credit && (
                        <StyledDd>
                          <StyledFlexBox>
                            <Thumbnail image={element.heroCredit?.src || null} />
                            <Box sx={{ pl: 2 }}>
                              <Richtext content={element.heroCredit.credit} />
                            </Box>
                          </StyledFlexBox>
                        </StyledDd>
                      )}
                      {element.inContentCredit &&
                        element.inContentCredit.map((item) => (
                          <StyledDd sx={{ ml: 4, display: 'block' }}>
                            <StyledFlexBox>
                              
                              <Thumbnail image={item.src} />
                              <Box sx={{ pl: 2 }}>
                                <Richtext content={item.credit} />
                              </Box>
                            </StyledFlexBox>
                          </StyledDd>
                        ))}
                    </Box>
                  );
                }
              })}
          </dl>
        </Box>
      </Grid>
    </ModuleWrapper>
  );
};

const Thumbnail = ({image}) => {
  const isSVG = ['image/svg+xml'].includes(image.asset.mimeType)
  
  if(isSVG) {
    return <img src={image.asset.url} width={64}/>
  }

  const imageData = getImage(image.asset)
  return <GatsbyImage image={imageData} />
};

export default ModuleCredit;
