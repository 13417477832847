import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { Box, Text } from 'theme-ui';
import Button from '../../Button';
import Heading from '../../Heading';
import ModuleWrapper from '../ModuleWrapper';
const EmotionInlineWidget = styled(InlineWidget)``;

const ModuleCalendly = ({
  account,
  event,
  spaceAbove,
  spaceBelow,
  variant,
  cookieConsentLabel,
  cookieConsent,
  title,
}) => {
  const [calendlyCookieAccepted, setCalendlyCookieAccepted] = useState(false);

  const handleAcceptCookie = () => {
    window.localStorage.setItem('calendlyCookieAccepted', 'true');
    setCalendlyCookieAccepted(true);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCalendlyCookieAccepted(window.localStorage.getItem('calendlyCookieAccepted') === 'true');
    }
  }, []);
  return (
    <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant}>
      {calendlyCookieAccepted ? (
        <EmotionInlineWidget
          url={`https://calendly.com/${account}/${event}?hide_gdpr_banner=1`}
          sx={{
            height: '100%',
          }}
        />
      ) : (
        <Box sx={{ mx: 'auto', mt: 6, justifyContent: 'center', width: 'max-content', textAlign: 'center' }}>
          <Heading variant={'h3'} as={'h3'} sx={{ mb: 6 }}>
            {title}
          </Heading>
          <Text variant={'text.default'} as="p" sx={{ mb: 6 }}>
            {cookieConsent}
          </Text>
          <Button variant={'solid'} onClick={handleAcceptCookie} sx={{ margin: 'auto' }}>
            {cookieConsentLabel}
          </Button>
        </Box>
      )}
    </ModuleWrapper>
  );
};

ModuleCalendly.propTypes = {
  account: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  ...ModuleWrapper.propTypes,
};

export default ModuleCalendly;
