import PropTypes from 'prop-types';
import React from 'react';
import { useEffect } from 'react';
import ModuleWrapper from '../ModuleWrapper';

const ModuleDemodesk = ({ account, event, spaceAbove, spaceBelow, variant }) => {
  useEffect(() => {
    var r = 'https://demodesk.com';
    var t = /([.*+?\^${}()|\[\]\/\\])/;
    function n(e) {
      return e.replace(t, '\\$1');
    }
    function e(e) {
      var t = document.cookie.match(RegExp('(?:^|;\\s*)' + n(e) + '=([^;]*)'));
      return t && t[1];
    }
    function a(e) {
      var t = r + `/book/${account}/${event}?iframe=true`;
      return e ? t + '&hutk=' + e : t;
    }
    var i = document.createElement('iframe');
    i.style = 'width: 100%; min-height: 230px;';
    i.src = a(e('hubspotutk'));
    var m = document.createElement('script');
    m.src = r + '/iframe_resize.js';
    var c = document.getElementById('demodesk-iframe-inserter');
    var o = c.parentElement;
    o.replaceChild(i, c);
    o.insertBefore(m, i.nextSibling);
  }, []);
  return (
    <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant}>
      <div id="demodesk-iframe-inserter"></div>
    </ModuleWrapper>
  );
};

ModuleDemodesk.propTypes = {
  account: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  ...ModuleWrapper.propTypes,
};

export default ModuleDemodesk;
