import { AspectRatio, Box, Flex, Grid } from '@theme-ui/components';
import { motion } from 'framer-motion';
// import Img from 'gatsby-image';
import { GatsbyImage } from "gatsby-plugin-image";

import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useThemeUI } from 'theme-ui';
import ModuleWrapper from '../ModuleWrapper';

const ModuleImageRow = ({ component, spaceAbove, spaceBelow, variant }) => {
  const [refLeft, inViewLeft] = useInView({ triggerOnce: false });
  const [refRight, inViewRight] = useInView({ triggerOnce: false });
  const overflowIndicatorVariants = {
    hidden: { opacity: 0, width: 0 },
    visible: { opacity: 1, width: 'min(10vw, 100px)' },
  };
  const { theme } = useThemeUI();

  const commonOverflowIndicatorStyles = {
    display: 'block',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: 'min(10vw, 100px)',
    zIndex: 1,
    pointerEvents: 'none',
  };

  const themeBackgroundColor = theme?.colors?.backgrounds[variant];
  return (
    <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant} sx={{ position: 'relative' }}>
      <Grid gap={2} columns={1} variant="default" sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex' }}>
          <motion.div
            animate={inViewLeft ? 'hidden' : 'visible'}
            variants={overflowIndicatorVariants}
            style={{
              ...commonOverflowIndicatorStyles,
              background: `linear-gradient(90deg, ${themeBackgroundColor}, rgba(255,255,255,0))`,
              left: '-1px',
            }}
          ></motion.div>
          <motion.div
            animate={inViewRight ? 'hidden' : 'visible'}
            variants={overflowIndicatorVariants}
            style={{
              ...commonOverflowIndicatorStyles,
              background: `linear-gradient(-90deg, ${themeBackgroundColor}, rgba(255,255,255,0))`,
              right: '-1px',
            }}
          ></motion.div>
          <Flex
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              overflowY: 'hidden',
              scrollSnapType: 'x mandatory ',
              position: 'relative',
            }}
          >
            <Box
              ref={refLeft}
              sx={{ position: 'absolute', left: 0, width: '1px', border: '1px solid transparent', height: '100px' }}
            ></Box>
            {component.images.map(content => {
                const aspectRatio = content.asset?.metadata?.dimensions?.aspectRatio || 1;
                const image = content.asset?.gatsbyImageData 
              
              return (
                <Box
                  key={content.id}
                  sx={{
                    flexGrow: 0,
                    minWidth: [`calc(80% - ${2 * theme.sizes[4]}px)`, null, `calc(25% - ${2 * theme.sizes[4]}px)`],
                    scrollSnapAlign: 'center',
                    margin: 4,
                  }}
                >
                  
                  <AspectRatio ratio={1}>
                    <GatsbyImage image={image} title={content.caption}
              width={180}
              height={180}
              alt={content.alt}
              style={{ width: '100%', height: '100%' }}
              />
                  </AspectRatio>
                </Box>
              );
            })}
            <Box
              ref={refRight}
              sx={{ position: 'absolute', right: 0, width: '1px', border: '1px solid transparent', height: '100px' }}
            ></Box>
          </Flex>
        </Box>
      </Grid>
    </ModuleWrapper>
  );
};

ModuleImageRow.propTypes = {
  ...ModuleWrapper.propTypes,
  component: PropTypes.shape({
    images: PropTypes.array.isRequired,
  }),
};

ModuleImageRow.defaultProps = {};

export default ModuleImageRow;
