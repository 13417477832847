/** @jsx jsx */

import { jsx, Themed, Text } from 'theme-ui';
import React from 'react';
import Link from '../Link';
import List from '../List';
import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';

const BlockContent = require('@sanity/block-content-to-react');

function ListSerializer(props) {
  // const tag = props.type === 'bullet' ? 'dl' : 'ol';
  // return React.createElement(List, { type: 'unordered', items: props.children });
  return (
    <List
      items={props.children}
      type={props.type === 'bullet' ? 'unordered' : 'ordered'}
      sx={{
        fontFamily: 'body',
        mb: 4,
        li: {
          listStyle: props.type === 'bullet' ? 'disc outside none' : 'decimal outside none',
          ml: 3,
          mb: 1,
        },
      }}
    />
  );
}

// Low-level list item serializer
function ListItemSerializer(props) {
  const children =
    !props.node.style || props.node.style === 'normal'
      ? // Don't wrap plain text in paragraphs inside of a list item
      props.children
      : // But wrap any other style in whatever the block serializer says to use
      React.createElement(props.serializers.types.block, props, props.children);

  return React.createElement(React.Fragment, null, children);
}

const serializers = {
  marks: {
    internalLink: ({ mark, children }) => {
      const { href } = mark;
      const data = useStaticQuery(graphql`
        {
          allSanityPage {
            edges {
              node {
                _id
                slug {
                  current
                }
              }
            }
          }
        }
      `);

      const { edges: pages } = data.allSanityPage;
      const slug = pages.find(({ node: page }) => page._id === mark.reference._ref).node.slug.current;
      return (
        <Link
          to={slug}
          variant="link.default"
          sx={{
            color: 'primary',
            '&:hover': {
              color: 'primaryActive',
            },
          }}
        >
          {children}
        </Link>
      );
    },
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href, url } = mark;
      return blank ? (
        <Link to={href || url} variant="link.default" target="_blank" rel="noopener">
          {children}
        </Link>
      ) : (
        <Link
          to={href || url}
          variant="link.default"
          sx={{
            color: 'primary',
            '&:hover': {
              color: 'primaryActive',
            },
          }}
        >
          {children}
        </Link>
      );
    },
  },
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return <Themed.h1>{props.children}</Themed.h1>;
        case 'h2':
          return <Themed.h2>{props.children}</Themed.h2>;
        case 'h3':
          return <Themed.h3>{props.children}</Themed.h3>;
        case 'h4':
          return <Themed.h4>{props.children}</Themed.h4>;
        case 'h5':
          return <Themed.h5>{props.children}</Themed.h5>;
        case 'h6':
          return <Themed.h6>{props.children}</Themed.h6>;
        case 'a':
          return <Themed.h6>{props.children}</Themed.h6>;
        case 'strong':
          return <Themed.strong>{props.children}</Themed.strong>;

        case 'blockquote':
          return <Text variant={'text.default'} as="blockquote" sx={{ mb: 6, pl: 3, borderLeft: '4px solid', borderColor: 'primary' }}>
            {props.children}
          </Text>


        default:
          return (
            <Text variant={'text.default'} as="p" sx={{ mb: 6 }}>
              {props.children}
            </Text>
          );
      }
    },
  },
  list: ListSerializer,
  // list: props => {
  //   return <List items={props.children} type={'checkmark'} />
  // },
  listItem: ListItemSerializer,
};

const Richtext = ({ content, sx }) => {
  return <BlockContent blocks={content} serializers={serializers} sx={sx} />;
};

export default Richtext;
