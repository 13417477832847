import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Text } from 'theme-ui';
import Button from '../Button';
import DefinitionList from '../DefinitionList';
import Heading from '../Heading';
import Link from '../Link';
import { ReactComponent as Download } from '../Icon/assets/download.svg';
import ModuleTextWithImage from '../modules/ModuleTextWithImage';
import ModuleWrapper from '../modules/ModuleWrapper';
const slugify = require('../../utils/slugify');

const ContactCards = ({
  firstName,
  id,
  lastName,
  role,
  workEmail,
  workPhone,
  workFax,
  bio,
  showDownload,
  showBooking,
  image,
  spaceAbove,
  spaceBelow,
  variant,
}) => {
  let contactItems = [];
  workPhone &&
    contactItems.push({
      type: 'phone',
      value: (
        <Text variant={'text.default'} as="p" sx={{ mb: 2 }}>
          {workPhone}
        </Text>
      ),
    });
  workFax &&
    contactItems.push({
      type: 'fax',
      value: (
        <Text variant={'text.default'} as="p" sx={{ mb: 2 }}>
          {workFax}
        </Text>
      ),
    });
  workEmail &&
    contactItems.push({
      type: 'mail',
      value: (
        <Text variant={'text.default'} as="p" sx={{ mb: 2 }}>
          <Link variant="links.default" sx={{ color: 'primary' }} to={`mailto:${workEmail}`}>
            {workEmail}
          </Link>
        </Text>
      ),
    });

  return (
    <ModuleTextWithImage
      key={id}
      imagePosition={'right'}
      img={image}
      spaceAbove={spaceAbove}
      spaceBelow={spaceBelow}
      variant={variant}
    >
      <Heading as={'h3'} variant="h3" sx={{ mt: 2, mb: 3 }}>
        {`${firstName} ${lastName}`}
      </Heading>

      {role && (
        <Heading variant="h4" as="p" sx={{ mb: 7, color: 'muted' }}>
          {role}
        </Heading>
      )}

      <DefinitionList styles={{ mb: 2 }} items={contactItems} />
      {bio && <Text>{bio}</Text>}
      <Flex sx={{ mt: 3, flexDirection: ['column', 'row'] }}>
        {showBooking && (
          // TODO: this should somehow point to a configurable page
          <Button styles={{ mr: 2 }} to={`/de_DE/other/termin-vereinbaren`}>
            Termin buchen
          </Button>
        )}
        {showDownload && (
          <Button
            variant={'text'}
            to={`/vcf/${slugify(`${lastName}-${firstName}`)}.vcf`}
            download
            iconBefore={Download}
            sx={{ ml: 2 }}
          >
            Visitenkarten laden
          </Button>
        )}
      </Flex>
    </ModuleTextWithImage>
  );
};

ContactCards.propTypes = {
  bio: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  role: PropTypes.string,
  showDownload: PropTypes.bool,
  showBooking: PropTypes.bool,
  workEmail: PropTypes.string,
  workFax: PropTypes.string,
  workPhone: PropTypes.string,
  ...ModuleWrapper.propTypes,
};

ContactCards.defaultProps = {
  bio: null,
  role: null,
  showDownload: true,
  showBooking: true,
  workEmail: null,
  workFax: null,
  workPhone: null,
};

export default ContactCards;
