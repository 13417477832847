import { Box, Grid } from '@theme-ui/components';
import PropTypes from 'prop-types';
import React from 'react';
import ModuleWrapper from '../ModuleWrapper';

const ModuleTextCentered = ({ children, head, spaceAbove, spaceBelow, variant, ...props }) => (
  <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant}>
    <Grid gap={2} columns={[2, null, 12]} variant="default">
      <Box
        sx={{
          gridColumn: ['1/-1', null, '2/12'],
          mb: [props.component.title ? 9 : 0],
        }}
      >
        {head}
      </Box>
      <Box
        sx={{
          gridColumn: ['1/-1', null, '3/11'],
        }}
      >
        {children}
      </Box>
    </Grid>
  </ModuleWrapper>
);

ModuleTextCentered.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  head: PropTypes.element,
  ...ModuleWrapper.propTypes,
};

ModuleTextCentered.defaultProps = {
  head: null,
};

export default ModuleTextCentered;
