import { AspectRatio, Grid } from '@theme-ui/components';
import PropTypes from 'prop-types';
import React from 'react';
import ModuleWrapper from '../ModuleWrapper';
import Link from '../../Link';
import { GatsbyImage } from 'gatsby-plugin-image';

const ModulePartner = ({ partner, spaceAbove, spaceBelow, variant, ...props }) => (
  <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant}>
    <Grid gap={2} columns={[2, null, 4]} variant="default" {...props}>
      {partner.map((singlePartner, index) =>
        {
          const image = singlePartner.logo.asset;
        
          
          return singlePartner.url ? (
            <Link to={singlePartner.url} target="_blank" key={`partner_${index}_${singlePartner.name}`}>
              <PartnerImage image={image} aspectRatio={210 / 60} />
            </Link>
          ) : (
            <PartnerImage image={image} aspectRatio={210 / 60} />
          );
        }
      )}
    </Grid>
  </ModuleWrapper>
);

const PartnerImage = ({image, aspectRatio, alt = '', title=''}) => {
  
  const isSVG = ['image/svg+xml'].includes(image.mimeType)
  if(isSVG) {
    return <AspectRatio ratio={aspectRatio} sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={image.url}/>
      </AspectRatio>
  }

  
  return <AspectRatio ratio={aspectRatio} sx={{ display: 'flex', justifyContent: 'center' }}>
    <GatsbyImage
      image={image.gatsbyImageData}
      width={210}
      style={{
        height: 'auto',
        width: '100%'
      }}
      alt={alt}
      title={title} />


  </AspectRatio>;
}


ModulePartner.propTypes = {
  partner: PropTypes.array.isRequired,
  ...ModuleWrapper.propTypes,
};  

ModulePartner.defaultProps = {};

export default ModulePartner;

